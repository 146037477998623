var generic = generic || {};
var site = site || {};

(function ($) {
  var contentBlockSignup = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $body = $('body', context);
      var $module = $('.js-content-block-signup-v1', context);
      var $emailForm = $();
      var $emailInput = $();
      var $emailSuccessMessage = $();
      var $emailErrorMessage = $();
      var $emailCountrySelectErrorMessage = $();
      var $emailAlreadySignedUpMessage = $();
      var errorMessage = $module.data('errorText');
      var alreadySignedupMessage = $module.data('signedUpText');
      var params = {};
      var emailAddress = '';
      var filter = '';
      var isFormReady = false;
      var hasCountrySelect = false;
      var observer;
      var $submitButton = $();
      var $thisModule = $();

      if ($module.length < 1) {
        return;
      }

      $module.each(function () {
        $thisModule = $(this);
        $emailForm = $thisModule.find('.js-content-block-signup__form');
        $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        $emailSuccessMessage = $thisModule.find('.js-content-block-signup__success');
        $emailErrorMessage = $thisModule.find('.js-content-block-signup__error');
        $emailCountrySelectErrorMessage = $thisModule.find('.js-content-block-signup__country-select-error');
        $emailAlreadySignedUpMessage = $thisModule.find(
          '.js-content-block-signup__already-signed-up'
        );
        $submitButton = $thisModule.find('.js-content-block-signup__submit');
        hasCountrySelect = $emailForm.hasClass('js-content-block-signup--has-country-select');

        $emailInput.on('keyup', function (e) {
          e.preventDefault();

          emailAddress = $(this).val();
          filter = /^([a-zA-Z0-9_\.\+\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          if (!filter.test(emailAddress)) {
            if (e.key === 'Enter') {
              $emailForm.addClass('content-block-signup__form--email-error');
              $thisModule.addClass('content-block-signup--active-error');
              $emailErrorMessage.removeClass('hidden').html(errorMessage);
            }
          } else if (e.key === 'Enter') {
            if (!$emailForm.hasClass('content-block-signup__form--country-select-active')) {
              $thisModule.addClass('content-block-signup--active-error');
              $emailCountrySelectErrorMessage.removeClass('hidden');
              $emailForm.addClass('content-block-signup__form--country-select-error');
            }
          } else {
            $emailForm.addClass('content-block-signup__form--email-active');
            $emailForm.removeClass('content-block-signup__form--email-error');
          }

          if ($(this).val() === '') {
            $(this).removeClass('non-empty');
          } else {
            $(this).addClass('non-empty');
          }
        });

        observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.target.classList.contains('content-block-signup__form--country-select-active') && mutation.target.classList.contains('content-block-signup__form--email-active')) {
              $thisModule.removeClass('content-block-signup--active-error');
              $emailCountrySelectErrorMessage.addClass('hidden');
              $submitButton.removeClass('button--disabled');
              $emailInput.removeClass('error');
              isFormReady = true;
            } else if (mutation.target.classList.contains('content-block-signup__form--email-active') && hasCountrySelect === false) {
              $submitButton.removeClass('button--disabled');
              $emailInput.removeClass('error');
              isFormReady = true;
            } else if (mutation.target.classList.contains('content-block-signup__form--country-select-error') && hasCountrySelect === true) {
              $submitButton.addClass('button--disabled');
              isFormReady = false;
            }
          });
        });

        observer.observe($emailForm.get(0), {
          attributes: true,
          attributeFilter: ['class']
        });

        $emailForm.once().on('submit', function (e) {
          e.preventDefault();
          $thisModule.removeClass('content-block-signup--active-error');
          $(this).removeClass('content-block-signup__form--country-select-error');
          $emailCountrySelectErrorMessage.addClass('hidden');
          $emailErrorMessage.addClass('hidden');
          $emailSuccessMessage
            .add($emailErrorMessage)
            .add($emailAlreadySignedUpMessage)
            .addClass('hidden');
          $emailInput.removeClass('error');

          // String into array of form elements
          params.PC_EMAIL_PROMOTIONS = 0;
          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          if ($.cookie('csrftoken')) {
            params['_TOKEN'] = $.cookie('csrftoken');
          }

          if (isFormReady === true) {
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [params],
              onSuccess: function (jsonRpcResponse) {
                $thisModule.addClass('content-block-signup--active-success');
                $body.addClass('content-block-signup--complete');
                var response = jsonRpcResponse.getData();
                var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

                if (alreadySignedUp) {
                  $emailAlreadySignedUpMessage.removeClass('hidden').html(alreadySignedupMessage);
                } else {
                  $emailSuccessMessage.removeClass('hidden');
                }
                $emailSuccessMessage.removeClass('hidden');
                $emailInput.val('').removeClass('non-empty');
                $(document).trigger('contentBlock.signupSuccess', [$thisModule]);
              },
              onFailure: function (jsonRpcResponse) {
                var errObjs = jsonRpcResponse.getMessages();
                var errors = '';
                var i;
                var myErr;

                $emailErrorMessage.removeClass('hidden');
                for (i = 0; i < errObjs.length; i++) {
                  myErr = errObjs[i];
                  if (myErr && myErr.key) {
                    if (
                      myErr.key === 'required.pc_email_address.email_signup' ||
                      myErr.key === 'invalid.pc_email_address.email_signup'
                    ) {
                      errors += myErr.text;
                      $emailInput.addClass('error').focus();
                    }
                  }
                }
                if (errorMessage) {
                  $thisModule.addClass('content-block-signup--active-error');
                  $emailErrorMessage.html(errorMessage);
                } else if (errors) {
                  $thisModule.addClass('content-block-signup--active-error');
                  $emailErrorMessage.html(errors);
                }
              }
            });
          }
        });
      });
    },
    overrideInputSelect: function (countryId, languageId, $context) {
      if (!countryId || !languageId || $context.length < 1) {
        return;
      }
      var $languageInput = $context.find('.js-content-block-signup__language-id-input');
      var $countryInput = $context.find('.js-content-block-signup__country-id-input');

      $languageInput.val(languageId);
      $countryInput.val(countryId);
      $context.addClass('content-block-signup__form--country-select-active');
      $context.removeClass('content-block-signup__form--country-select-error');
    },
    inputSelectEmpty: function ($context) {
      if ($context.length < 1) {
        return;
      }

      $context.removeClass('content-block-signup__form--country-select-active');
      $context.addClass('content-block-signup__form--country-select-error');
    }
  };

  Drupal.behaviors.contentBlockSignupV1 = {
    attached: false,
    attach: function (context) {
      contentBlockSignup.setup(context);
    }
  };

  $(document).on('contentBlock.countrySelectChange', function (e, countryId, languageId, $context) {
    contentBlockSignup.overrideInputSelect(countryId, languageId, $context);
  });
  $(document).on('contentBlock.countrySelectChangeEmpty', function (e, $context) {
    contentBlockSignup.inputSelectEmpty($context);
  });
})(jQuery);
